<template>
  <div>
    <v-card>
      <v-toolbar
        color="#0D47A1"
        dark
        height="44px"
      >
        <v-toolbar-title>
          承接方案
        </v-toolbar-title>

        <v-spacer />

        <div
          v-if="!isXs || !orgQX"
        >
          <v-btn
            v-if="orgQX"
            small
            color="blue mt-0"
            @click="openPlan"
          >
            新增承接区县
          </v-btn>
          <v-btn
            v-if="orgQX"
            small
            color="blue mt-0 ml-4"
            @click="openTsd"
          >
            承办意见及推送单
          </v-btn>
          <v-btn
            small
            color="blue mt-0 ml-4"
            @click="exportTable"
          >
            推送单下载
          </v-btn>
        </div>

        <v-menu
          v-if="isXs && orgQX"
          offset-y
          :close-on-content-click="false"
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-icon
              class="mx-4"
              color="#fff"
              v-on="on"
            >
              fa4 mdi-format-list-bulleted
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="orgQX"
              @click="openPlan"
            >
              <v-list-item-title>
                新增承接区县
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="orgQX"
              @click="openTsd"
            >
              <v-list-item-title>
                承办意见及推送单
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="exportTable"
            >
              <v-list-item-title>
                推送单下载
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-container class="mt-2">
        <v-row>
          <v-col
            md="3"
          >
            <v-text-field
              v-model="parks"
              class="fildName"
              label="接收单位"
              dense
              clearable
              solo
            />
          </v-col>
          <v-col
            md="3"
          >
            <v-text-field
              v-model="name"
              class="fildName"
              label="拟承接县市区（含园区）"
              dense
              clearable
              solo
            />
          </v-col>
          <v-col
            md="2"
            class="pl-1"
          >
            <v-btn
              elevation="1"
              class="ma-1"
              color="blue mt-0"
              @click="searchChild"
            >
              <v-icon left>
                fa4 fa-search
              </v-icon>
              搜索
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table fixed-header>
          <thead>
            <tr :class="isXs ? 'titles' : ''">
              <th
                style="min-width:84px"
              >
                接收单位
              </th>
              <th
                style="min-width:100px"
              >
                市项目联络人
              </th>
              <th style="min-width:100px">
                部门及职务
              </th>
              <th
                style="min-width:84px"
              >
                联系方式
              </th>
              <th style="min-width:150px">
                承办意见
              </th>
              <th
                style="min-width:150px"
              >
                推送单
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,inx) in desserts2"
            v-show="!orgQX && item.data.contacts"
            :key="inx"
          >
            <tr
              :class="isXs ? 'names' : ''"
            >
              <td>{{ item.data.parks }}</td>
              <td>{{ item.data.contacts }}</td>
              <td>{{ item.data.job }}</td>
              <td>{{ item.data.tel }}</td>
              <td>
                {{ item.data.underTake }}
              </td>
              <td>
                <files
                  :file="item.data.files"
                />
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="orgQX && openItem.data.contacts"
            :key="inx"
          >
            <tr>
              <td>{{ openItem.data.park }}</td>
              <td>{{ openItem.data.contacts }}</td>
              <td>{{ openItem.data.job }}</td>
              <td>{{ openItem.data.tel }}</td>
              <td>
                {{ openItem.data.underTake }}
              </td>
              <td>
                <files
                  :file="openItem.data.files"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table fixed-header>
          <thead>
            <tr :class="isXs ? 'titles' : ''">
              <th
                style="min-width:84px"
              >
                接收单位
              </th>
              <th
                style="min-width:100px"
              >
                拟承接县市区（含园区）
              </th>
              <th style="min-width:110px">
                项目承接负责人
              </th>
              <th style="min-width:100px">
                部门及职务
              </th>
              <th style="min-width:84px">
                联系方式
              </th>
              <th style="min-width:84px">
                项目承办人
              </th>
              <th style="min-width:84px">
                部门及职务
              </th>
              <th style="min-width:84px">
                联系方式
              </th>
              <th style="min-width:150px">
                推荐方案
              </th>
              <th style="min-width:120px">
                操作
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(item,inx) in desserts"
            :key="inx"
          >
            <tr
              :class="isXs ? 'names' : ''"
            >
              <td>{{ item.data.parks }}</td>
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.data.contacts }}</td>
              <td>{{ item.data.Department }}</td>
              <td>{{ item.data.tel }}</td>
              <td>{{ item.data.undertaker }}</td>
              <td>{{ item.data.positions }}</td>
              <td>{{ item.data.information }}</td>
              <td>
                <!-- <div
                  v-for="(img,inx) in item.data.files"
                  :key="inx"
                >
                  <v-img
                    style="width:70px;height:120px;float:left"
                    :src="img.url"
                    :lazy-src="img.url"
                    contain
                    class="mr-1"
                    @click="imageMaxModel(img.sourceUrl, img.oldName)"
                  />
                </div> -->
                <files
                  :file="item.data.files"
                />
              </td>
              <td>
                <v-icon
                  style="cursor:pointer"
                  small
                  class="mr-2"
                  color="red"
                  @click="deleteMobelBtn(item)"
                >
                  fa4 fa-trash-o
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          v-model="nowPage"
          :length="pageNum"
          total-visible="10"
          color="indigo darken-4"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="pageChange"
        />
      </v-container>
      <v-dialog
        v-model="imageShow"
        max-width="700"
      >
        <v-card>
          <v-card-title
            class="text-h5"
          >
            {{ photoName }}
          </v-card-title>
          <v-card-text>
            <v-img
              :src="bigPhoto"
              aspect-ratio="1"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="imageShow = false"
            >
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <plan-report
      ref="PlanReport"
      @getchild="getchild"
    />

    <v-dialog
      v-model="isShow"
      :content-class="isXs ? 'dialogXs' :''"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>承办意见及推送单</v-toolbar-title>
          <v-spacer />
          <v-btn
            color="#0D47A1"
            @click="saveBtn()"
          >
            保存
          </v-btn>
        </v-toolbar>
        <v-row class="mt-6 ma-0">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class="pa-0 d-flex mt-2 px-2"
          >
            <h3
              class="text-start mb-0"
              style="font-weight:600;width:120px"
            >
              市项目联络人:
            </h3>
            <v-text-field
              v-model="contacts"
              class="fildName"
              label="市项目联络人"
              dense
              clearable
              solo
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class="pa-0 d-flex mt-2 px-2"
          >
            <h3
              class="text-start mb-0"
              style="font-weight:600;width:120px"
            >
              部门及职务:
            </h3>
            <v-text-field
              v-model="job"
              class="fildName"
              label="部门及职务"
              dense
              clearable
              solo
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class="pa-0 d-flex mt-2 px-2"
          >
            <h3
              class="text-start mb-0"
              style="font-weight:600;width:120px"
            >
              联系方式:
            </h3>
            <v-text-field
              v-model="tel"
              class="fildName"
              label="联系方式"
              dense
              clearable
              solo
            />
          </v-col>
        </v-row>
        <!--  <div
          class="d-flex align-center mt-6"
        >
          <h3
            class="text-start pl-2 mb-0"
            style="font-weight:600;"
          >
            市项目联络人:
          </h3>
          <v-col
            cols="2 pt-0 pb-0"
          >
            <v-text-field
              v-model="contacts"
              class="fildName"
              label="市项目联络人"
              dense
              clearable
              solo
            />
          </v-col>
          <h3
            class="text-start pl-3 mb-0"
            style="font-weight:600;"
          >
            部门及职务:
          </h3>
          <v-col
            cols="2 pt-0 pb-0"
          >
            <v-text-field
              v-model="job"
              class="fildName"
              label="部门及职务"
              dense
              clearable
              solo
            />
          </v-col>
          <h3
            class="text-start pl-3 mb-0"
            style="font-weight:600;"
          >
            联系方式:
          </h3>
          <v-col
            cols="2 pt-0 pb-0"
          >
            <v-text-field
              v-model="tel"
              class="fildName"
              label="联系方式"
              dense
              clearable
              solo
            />
          </v-col>
        </div> -->
        <h3
          class="text-start pa-2 pb-0"
          style="font-weight:600"
        >
          承办意见:
        </h3>
        <v-col
          cols="12 px-2 pt-0"
        >
          <v-textarea
            v-model="underTake"
            solo
            name="input-7-4"
            label="承办意见"
          />
        </v-col>
        <h3
          class="text-start pa-2 pb-0 pt-0"
          style="font-weight:600"
        >
          推送单:
        </h3>
        <v-col
          cols="12 px-2 pt-0"
        >
          <upload
            :img-number="1"
            :img-icon="imgIcon"
            :value="pushOrder"
            :up-type="upType"
            @input="inputFile"
          />
        </v-col>
        <v-col
          cols="12 pl-2 pr-6 pt-0"
        >
          <span
            style="color:#2196f3"
            class="pr-2"
          >省商务厅重点外资项目跟踪推进推送单.doc</span>
          <v-btn
            small
            color="blue mt-0"
            :disabled="isDown"
            @click="exportTable"
          >
            点击下载
          </v-btn>
          <p
            style="color:#B5B5B5"
          >
            (输入框信息填写完方可点击下载)
          </p>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import file from '../../../utils/file'
  export default {
    components: {
      files: () => import('../component/Files.vue'),
      PlanReport: () => import('../../../views/report/PlanReport.vue'),
      Upload: () => import('../component/Upload.vue'),
    },
    props: {
      formId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        isDown: true,
        imgIcon: 'md-document',
        parkCode: [],
        childformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        desserts: [],
        desserts2: [],
        parentList: {},
        createData: '',
        parks: '',
        searchParame: {
          page: 0,
          size: 5,
          formId: '7354c8f1-3cfb-463a-b0a4-e13623c0051b',
        },
        name: '',
        imageShow: false,
        nowPage: 1,
        pageNum: 0,
        orgQX: false,
        openItem: {},
        isShow: false,
        contacts: '',
        tel: '',
        job: '',
        underTake: '',
        pushOrder: [],
        upType: ['pdf', 'doc', 'docx'],
        isXs: false,
      }
    },
    computed: {
      allDown () {
        const list = { contacts: this.contacts, tel: this.tel, job: this.job, underTake: this.underTake }
        return list
      },
    },
    watch: {
      allDown (list) {
        if (this.contacts && this.tel && this.job && this.underTake) {
          this.isDown = false
        } else {
          this.isDown = true
        }
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async mounted () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      if (this.orgQX) {
        await this.tsDetail()
      }
      await this.getDetail()
      await this.getchild()
      await this.getchild2()
    },
    methods: {
      async openPlan () {
        await this.tsDetail()
        this.$refs.PlanReport.open(this.openItem)
      },
      async openTsd () {
        await this.tsDetail()
        this.isShow = true
        this.$nextTick(() => {
          if (this.openItem.data.pushOrder) {
            this.pushOrder = this.openItem.data.pushOrder.split(',')
          }
          if (this.openItem.data.underTake) {
            this.underTake = this.openItem.data.underTake
          }
          if (this.openItem.data.contacts) {
            this.contacts = this.openItem.data.contacts
          }
          if (this.openItem.data.tel) {
            this.tel = this.openItem.data.tel
          }
          if (this.openItem.data.job) {
            this.job = this.openItem.data.job
          }
        })
      },
      inputFile (val) {
        this.pushOrder = val
      },
      async saveBtn () {
        if (!this.pushOrder.length || !this.underTake || !this.contacts || !this.tel || !this.job) {
          return this.$swal({
            title: '请检查未填写项!',
            icon: 'error',
          })
        }
        const resp = await this.$axios.patch(`/reports/${this.$route.query.dynamicId}`,
                                             { pushOrder: this.pushOrder.toString(), underTake: this.underTake, contacts: this.contacts, tel: this.tel, job: this.job },
        )
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '保存失败!',
            icon: 'error',
          })
        }
        this.$swal({
          title: '保存成功!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
        if (this.orgQX) {
          await this.tsDetail()
        } else {
          this.searchChild()
        }
        this.isShow = false
      },
      async tsDetail () {
        const resp = await this.$axios.get('/reports/' + this.$route.query.dynamicId + '/detail')
        const openItem = resp.data.data
        if (openItem.data.pushOrder) {
          this.$set(openItem.data, 'files', await file.idToUrl(openItem.data.pushOrder))
        }

        this.$nextTick(() => {
          if (this.openItem.data.pushOrder) {
            this.pushOrder = this.openItem.data.pushOrder.split(',')
          }
          if (this.openItem.data.underTake) {
            this.underTake = this.openItem.data.underTake
          }
        })
        this.openItem = openItem
      },
      async searchChild () {
        this.searchParame.page = 0
        this.nowPage = 1
        await this.getchild()
        await this.getchild2()
      },
      //! 弹出图片大图
      imageMaxModel (bigPhoto, photoName) {
        this.imageShow = true
        this.bigPhoto = bigPhoto
        this.photoName = photoName
      },
      async getDetail () {
        const resp = await this.$axios.get('/reports/' + this.reportId + '/detail')
        console.log('resp', resp);
        this.parentList = resp.data.data
      },
      deleteMobelBtn (item) { // 删除按钮弹出弹框
        this.$swal({
          title: '你确定要删除此承接方案吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(item)
          }
        })
      },
      deleteProject (item) { // 删除项目
        this.$axios.delete('/reports/' + item.id)
          .then(async (res) => {
            this.desserts.splice(this.desserts.indexOf(item), 1)
            console.log('this.desserts', this.desserts);
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },

      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      // 导出
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.$axios.get(`/foreignInvestment/pushdoc/${this.reportId}?contacts=${this.contacts}&job=${this.job}&tel=${this.tel}&underTake=${this.underTake}`)
            .then(res => {
              const resData = res.config.url
              console.log('res', res);
              this.exportFile(resData)
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      // 导出文件
      async exportFile (url) {
        const data = await this.$axios.get(url, {
          responseType: 'blob',
        })
        console.log('data', data);
        const link = document.createElement('a')
        // link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' }))
        link.setAttribute('download', '省商务厅重点外资项目跟踪推进推送单.doc')
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      async getchild () {
        const paramsData = JSON.parse(JSON.stringify(this.searchParame))
        if (!this.orgQX) {
          paramsData._from = 'public'
        }
        paramsData.parentID = this.reportId
        if (this.parks) {
          paramsData.parks = this.parks
        } else {
          delete paramsData.parks
        }
        if (this.name) {
          paramsData.name = this.name
        } else {
          delete paramsData.name
        }
        if (this.createData[0]) {
          paramsData._createdFrom = this.$moment(this.createData[0]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdFrom
        }
        if (this.createData[1]) {
          paramsData._createdTo = this.$moment(this.createData[1]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdTo
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        console.log('resp', resp);

        const desserts = resp.data.data.content
        desserts.map(async val => {
          if (val.data.opinion) {
            this.$set(val.data, 'files', await file.idToUrl(val.data.opinion))
          }
        })
        this.pageNum = resp.data.data.totalPages
        this.desserts = desserts
      },
      async getchild2 () {
        const paramsData = { formId: this.childformId, page: 0, size: 999, parentID: this.reportId }
        if (!this.orgQX) {
          paramsData._from = 'public'
        }
        if (this.parks) {
          paramsData.parks = this.parks
        } else {
          delete paramsData.parks
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        const desserts2 = resp.data.data.content
        desserts2.map(async val => {
          if (val.data.pushOrder) {
            this.$set(val.data, 'files', await file.idToUrl(val.data.pushOrder))
          }
        })
        this.desserts2 = desserts2
      },
      pageChange (value) { // 换页按钮
        this.nowPage = value
        this.searchParame.page = value - 1
        this.getchild()
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep .v-dialog {
  height: auto !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
::v-deep .v-dialog{
  min-width: 800px;
  max-width: 1100px;
}
.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
::v-deep .dialogXs{
min-width:300px;
max-width:360px
}
</style>
